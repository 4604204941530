import { insertText } from './text.js';
import { shouldSkipFormatting } from './paste-keyboard-shortcut-helper.js';
const pasteLinkAsPlainTextOverSelectedTextMap = new WeakMap();
export function install(el, optionConfig) {
    var _a;
    pasteLinkAsPlainTextOverSelectedTextMap.set(el, ((_a = optionConfig === null || optionConfig === void 0 ? void 0 : optionConfig.defaultPlainTextPaste) === null || _a === void 0 ? void 0 : _a.urlLinks) === true);
    el.addEventListener('paste', onPaste);
}
export function uninstall(el) {
    el.removeEventListener('paste', onPaste);
}
function onPaste(event) {
    var _a;
    const { currentTarget: el } = event;
    const element = el;
    const shouldPasteAsPlainText = (_a = pasteLinkAsPlainTextOverSelectedTextMap.get(element)) !== null && _a !== void 0 ? _a : false;
    const shouldSkipDefaultBehavior = shouldSkipFormatting(element);
    if ((!shouldPasteAsPlainText && shouldSkipDefaultBehavior) ||
        (shouldPasteAsPlainText && !shouldSkipDefaultBehavior)) {
        return;
    }
    const transfer = event.clipboardData;
    if (!transfer || !hasPlainText(transfer))
        return;
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    const text = transfer.getData('text/plain');
    if (!text)
        return;
    if (!isURL(text))
        return;
    if (isWithinLink(field))
        return;
    const selectedText = field.value.substring(field.selectionStart, field.selectionEnd);
    if (!selectedText.length)
        return;
    if (isURL(selectedText.trim()))
        return;
    event.stopPropagation();
    event.preventDefault();
    insertText(field, linkify(selectedText, text.trim()));
}
function hasPlainText(transfer) {
    return Array.from(transfer.types).includes('text/plain');
}
function isWithinLink(textarea) {
    const selectionStart = textarea.selectionStart || 0;
    if (selectionStart > 1) {
        const previousChars = textarea.value.substring(selectionStart - 2, selectionStart);
        return previousChars === '](';
    }
    else {
        return false;
    }
}
function linkify(selectedText, text) {
    return `[${selectedText}](${text})`;
}
function isURL(url) {
    try {
        const parsedURL = new URL(url);
        return removeTrailingSlash(parsedURL.href).trim() === removeTrailingSlash(url).trim();
    }
    catch (_a) {
        return false;
    }
}
function removeTrailingSlash(url) {
    return url.endsWith('/') ? url.slice(0, url.length - 1) : url;
}
