import { insertText } from './text.js';
import { shouldSkipFormatting } from './paste-keyboard-shortcut-helper.js';
export function install(el) {
    el.addEventListener('paste', onPaste);
}
export function uninstall(el) {
    el.removeEventListener('paste', onPaste);
}
function onPaste(event) {
    const transfer = event.clipboardData;
    const { currentTarget: el } = event;
    if (shouldSkipFormatting(el))
        return;
    if (!transfer || !hasHTML(transfer))
        return;
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    if (isWithinUserMention(field)) {
        return;
    }
    let plaintext = transfer.getData('text/plain');
    const textHTML = transfer.getData('text/html');
    const textHTMLClean = textHTML.replace(/\u00A0/g, ' ').replace(/\uC2A0/g, ' ');
    if (!textHTML)
        return;
    plaintext = plaintext.trim();
    if (!plaintext)
        return;
    const parser = new DOMParser();
    const doc = parser.parseFromString(textHTMLClean, 'text/html');
    const walker = doc.createTreeWalker(doc.body, NodeFilter.SHOW_ALL, node => node.parentNode && isLink(node.parentNode) ? NodeFilter.FILTER_REJECT : NodeFilter.FILTER_ACCEPT);
    const markdown = convertToMarkdown(plaintext, walker);
    if (markdown === plaintext)
        return;
    event.stopPropagation();
    event.preventDefault();
    insertText(field, markdown);
}
function convertToMarkdown(plaintext, walker) {
    let currentNode = walker.firstChild();
    let markdown = plaintext;
    let markdownIgnoreBeforeIndex = 0;
    let index = 0;
    const NODE_LIMIT = 10000;
    while (currentNode && index < NODE_LIMIT) {
        index++;
        const text = isLink(currentNode)
            ? (currentNode.textContent || '').replace(/[\t\n\r ]+/g, ' ')
            : (currentNode === null || currentNode === void 0 ? void 0 : currentNode.wholeText) || '';
        if (isEmptyString(text)) {
            currentNode = walker.nextNode();
            continue;
        }
        if (!isLink(currentNode)) {
            markdownIgnoreBeforeIndex += text.replace(/[\t\n\r ]+/g, ' ').trimStart().length;
            currentNode = walker.nextNode();
            continue;
        }
        const markdownFoundIndex = markdown.indexOf(text, markdownIgnoreBeforeIndex);
        if (markdownFoundIndex >= 0) {
            const markdownLink = linkify(currentNode, text);
            markdown = markdown.slice(0, markdownFoundIndex) + markdownLink + markdown.slice(markdownFoundIndex + text.length);
            markdownIgnoreBeforeIndex = markdownFoundIndex + markdownLink.length;
        }
        currentNode = walker.nextNode();
    }
    return index === NODE_LIMIT ? plaintext : markdown;
}
function isWithinUserMention(textarea) {
    const selectionStart = textarea.selectionStart || 0;
    if (selectionStart === 0) {
        return false;
    }
    const previousChar = textarea.value.substring(selectionStart - 1, selectionStart);
    return previousChar === '@';
}
function isEmptyString(text) {
    return !text || (text === null || text === void 0 ? void 0 : text.trim().length) === 0;
}
function isLink(node) {
    var _a;
    return ((_a = node.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'a' && node.hasAttribute('href');
}
function hasHTML(transfer) {
    return transfer.types.includes('text/html');
}
function linkify(element, label) {
    const url = element.href || '';
    let markdown = '';
    if (isUserMention(element) || isTeamMention(element)) {
        markdown = label;
    }
    else if (isSpecialLink(element) || areEqualLinks(url, label)) {
        markdown = url;
    }
    else {
        markdown = `[${label}](${url})`;
    }
    return markdown;
}
function isSpecialLink(link) {
    return (link.className.indexOf('commit-link') >= 0 ||
        (!!link.getAttribute('data-hovercard-type') && link.getAttribute('data-hovercard-type') !== 'user'));
}
function areEqualLinks(link1, link2) {
    link1 = link1.slice(-1) === '/' ? link1.slice(0, -1) : link1;
    link2 = link2.slice(-1) === '/' ? link2.slice(0, -1) : link2;
    return link1.toLowerCase() === link2.toLowerCase();
}
function isUserMention(link) {
    var _a;
    return ((_a = link.textContent) === null || _a === void 0 ? void 0 : _a.slice(0, 1)) === '@' && link.getAttribute('data-hovercard-type') === 'user';
}
function isTeamMention(link) {
    var _a;
    return ((_a = link.textContent) === null || _a === void 0 ? void 0 : _a.slice(0, 1)) === '@' && link.getAttribute('data-hovercard-type') === 'team';
}
