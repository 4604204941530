import { insertText } from './text.js';
import { shouldSkipFormatting } from './paste-keyboard-shortcut-helper.js';
export function install(el) {
    el.addEventListener('dragover', onDragover);
    el.addEventListener('drop', onDrop);
    el.addEventListener('paste', onPaste);
}
export function uninstall(el) {
    el.removeEventListener('dragover', onDragover);
    el.removeEventListener('drop', onDrop);
    el.removeEventListener('paste', onPaste);
}
function onDrop(event) {
    const transfer = event.dataTransfer;
    if (!transfer)
        return;
    if (hasFile(transfer))
        return;
    if (!hasLink(transfer))
        return;
    const links = extractLinks(transfer);
    if (!links.some(isImageLink))
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    insertText(field, links.map(linkify).join(''));
}
function onDragover(event) {
    const transfer = event.dataTransfer;
    if (transfer)
        transfer.dropEffect = 'link';
}
function onPaste(event) {
    const { currentTarget: el } = event;
    if (shouldSkipFormatting(el))
        return;
    const transfer = event.clipboardData;
    if (!transfer || !hasLink(transfer))
        return;
    const links = extractLinks(transfer);
    if (!links.some(isImageLink))
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    insertText(field, links.map(linkify).join(''));
}
function linkify(link) {
    return isImageLink(link) ? `\n![](${link})\n` : link;
}
function hasFile(transfer) {
    return Array.from(transfer.types).indexOf('Files') >= 0;
}
function hasLink(transfer) {
    return Array.from(transfer.types).indexOf('text/uri-list') >= 0;
}
function extractLinks(transfer) {
    return (transfer.getData('text/uri-list') || '').split('\r\n');
}
const IMAGE_RE = /\.(gif|png|jpe?g)$/i;
function isImageLink(url) {
    return IMAGE_RE.test(url);
}
