let submittedForm = null;
function shouldResumeField(field, filter) {
    return !!field.id && filter(field) && field.form !== submittedForm;
}
function valueIsUnchanged(field) {
    if (field instanceof HTMLSelectElement) {
        return true;
    }
    else if (isHTMLCheckableInputElement(field)) {
        return field.checked !== field.defaultChecked;
    }
    else {
        return field.value !== field.defaultValue;
    }
}
function isPersistableElement(node) {
    return node instanceof HTMLInputElement || node instanceof HTMLTextAreaElement || node instanceof HTMLSelectElement;
}
function isHTMLCheckableInputElement(field) {
    return field instanceof HTMLInputElement && /checkbox|radio/.test(field.type);
}
export function persistResumableFields(id, options) {
    var _a, _b, _c, _d, _e, _f;
    const scope = (_a = options === null || options === void 0 ? void 0 : options.scope) !== null && _a !== void 0 ? _a : document;
    const selector = (_b = options === null || options === void 0 ? void 0 : options.selector) !== null && _b !== void 0 ? _b : '.js-session-resumable';
    const elements = (_c = options === null || options === void 0 ? void 0 : options.fields) !== null && _c !== void 0 ? _c : scope.querySelectorAll(selector);
    const keyPrefix = (_d = options === null || options === void 0 ? void 0 : options.keyPrefix) !== null && _d !== void 0 ? _d : 'session-resume:';
    const storageFilter = (_e = options === null || options === void 0 ? void 0 : options.storageFilter) !== null && _e !== void 0 ? _e : valueIsUnchanged;
    let storage;
    try {
        storage = (_f = options === null || options === void 0 ? void 0 : options.storage) !== null && _f !== void 0 ? _f : sessionStorage;
    }
    catch (_g) {
        return;
    }
    const key = `${keyPrefix}${id}`;
    const resumables = [];
    for (const el of elements) {
        if (isPersistableElement(el)) {
            resumables.push(el);
        }
    }
    let fields = resumables
        .filter(field => shouldResumeField(field, storageFilter))
        .map(field => {
        if (field instanceof HTMLSelectElement) {
            return [field.id, Array.from(field.selectedOptions).map(option => option.value)];
        }
        else {
            return [field.id, field.value];
        }
    });
    if (fields.length) {
        try {
            const previouslyStoredFieldsJson = storage.getItem(key);
            if (previouslyStoredFieldsJson !== null) {
                const previouslyStoredFields = JSON.parse(previouslyStoredFieldsJson);
                const fieldsNotReplaced = previouslyStoredFields.filter(function (oldField) {
                    return !fields.some(field => field[0] === oldField[0]);
                });
                fields = fields.concat(fieldsNotReplaced);
            }
            storage.setItem(key, JSON.stringify(fields));
        }
        catch (_h) {
        }
    }
}
export function restoreResumableFields(id, options) {
    var _a, _b;
    const keyPrefix = (_a = options === null || options === void 0 ? void 0 : options.keyPrefix) !== null && _a !== void 0 ? _a : 'session-resume:';
    let storage;
    try {
        storage = (_b = options === null || options === void 0 ? void 0 : options.storage) !== null && _b !== void 0 ? _b : sessionStorage;
    }
    catch (_c) {
        return;
    }
    const key = `${keyPrefix}${id}`;
    let fields;
    try {
        fields = storage.getItem(key);
    }
    catch (_d) {
    }
    if (!fields)
        return;
    const changedFields = [];
    const storedFieldsNotFound = [];
    for (const [fieldId, value] of JSON.parse(fields)) {
        const resumeEvent = new CustomEvent('session:resume', {
            bubbles: true,
            cancelable: true,
            detail: { targetId: fieldId, targetValue: value }
        });
        if (document.dispatchEvent(resumeEvent)) {
            const field = document.getElementById(fieldId);
            if (isPersistableElement(field)) {
                if (field instanceof HTMLSelectElement) {
                    for (const option of field.options) {
                        option.selected = value.includes(option.value);
                    }
                    changedFields.push(field);
                }
                else if (isHTMLCheckableInputElement(field)) {
                    field.checked = !field.defaultChecked;
                    changedFields.push(field);
                }
                else if (field.value === field.defaultValue) {
                    field.value = value;
                    changedFields.push(field);
                }
            }
            else {
                storedFieldsNotFound.push([fieldId, value]);
            }
        }
    }
    if (storedFieldsNotFound.length === 0) {
        try {
            storage.removeItem(key);
        }
        catch (_e) {
        }
    }
    else {
        storage.setItem(key, JSON.stringify(storedFieldsNotFound));
    }
    setTimeout(function () {
        for (const el of changedFields) {
            el.dispatchEvent(new CustomEvent('change', { bubbles: true, cancelable: true }));
        }
    }, 0);
}
export function setForm(event) {
    submittedForm = event.target;
    setTimeout(function () {
        if (event.defaultPrevented) {
            submittedForm = null;
        }
    }, 0);
}
