export function insertText(textarea, text) {
    var _a, _b, _c;
    const before = textarea.value.slice(0, (_a = textarea.selectionStart) !== null && _a !== void 0 ? _a : undefined);
    const after = textarea.value.slice((_b = textarea.selectionEnd) !== null && _b !== void 0 ? _b : undefined);
    let canInsertText = true;
    textarea.contentEditable = 'true';
    try {
        canInsertText = document.execCommand('insertText', false, text);
    }
    catch (error) {
        canInsertText = false;
    }
    textarea.contentEditable = 'false';
    if (canInsertText && !textarea.value.slice(0, (_c = textarea.selectionStart) !== null && _c !== void 0 ? _c : undefined).endsWith(text)) {
        canInsertText = false;
    }
    if (!canInsertText) {
        try {
            document.execCommand('ms-beginUndoUnit');
        }
        catch (e) {
        }
        textarea.value = before + text + after;
        try {
            document.execCommand('ms-endUndoUnit');
        }
        catch (e) {
        }
        textarea.dispatchEvent(new CustomEvent('change', { bubbles: true, cancelable: true }));
    }
}
