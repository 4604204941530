import { insertText } from './text.js';
import { shouldSkipFormatting } from './paste-keyboard-shortcut-helper.js';
export function install(el) {
    el.addEventListener('paste', onPaste);
}
export function uninstall(el) {
    el.removeEventListener('paste', onPaste);
}
function onPaste(event) {
    const { currentTarget: el } = event;
    if (shouldSkipFormatting(el))
        return;
    const transfer = event.clipboardData;
    if (!transfer || !hasMarkdown(transfer))
        return;
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    const text = transfer.getData('text/x-gfm');
    if (!text)
        return;
    event.stopPropagation();
    event.preventDefault();
    insertText(field, text);
}
function hasMarkdown(transfer) {
    return Array.from(transfer.types).indexOf('text/x-gfm') >= 0;
}
