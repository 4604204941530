const skipFormattingMap = new WeakMap();
function setSkipFormattingFlag(event) {
    const { currentTarget: el } = event;
    const isSkipFormattingKeys = event.code === 'KeyV' && (event.ctrlKey || event.metaKey) && event.shiftKey;
    if (isSkipFormattingKeys || (isSkipFormattingKeys && event.altKey)) {
        skipFormattingMap.set(el, true);
    }
}
function unsetSkipFormattedFlag(event) {
    const { currentTarget: el } = event;
    skipFormattingMap.delete(el);
}
export function shouldSkipFormatting(el) {
    var _a;
    const shouldSkipFormattingState = (_a = skipFormattingMap.get(el)) !== null && _a !== void 0 ? _a : false;
    return shouldSkipFormattingState;
}
export function installAround(el, installCallbacks, optionConfig) {
    el.addEventListener('keydown', setSkipFormattingFlag);
    for (const installCallback of installCallbacks) {
        installCallback(el, optionConfig);
    }
    el.addEventListener('paste', unsetSkipFormattedFlag);
}
export function uninstall(el) {
    el.removeEventListener('keydown', setSkipFormattingFlag);
    el.removeEventListener('paste', unsetSkipFormattedFlag);
}
